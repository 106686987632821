import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import DeviceService from 'services/commons/DeviceService';

import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import { ADD, MEASURERS } from 'constants/routes';
import { filter, find, isEmpty } from 'lodash';
import Device from 'models/Device';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { currentConfiguredDevice } from '../../../atoms/currentConfiguredDevice';

function DeviceDetails(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  const deviceService = new DeviceService();
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState<string>('');

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const setCurrentConfiguredDevice = useSetRecoilState<Device | null>(
    currentConfiguredDevice,
  );

  const { isLoading, data: devices } = useQuery(
    'devices',
    () => deviceService.getAll().then((res) => res),
    { refetchOnMount: 'always' },
  );

  const handleSearch = async (inputValue: string) => {
    try {
      setLoading(true);
      // Simulate a server-side search, replace this with your actual API call
      const response = await deviceService.getAvailableDevices(inputValue);
      const availableDevicesNotAlreadyImported = filter(response, (item) => {
        const existing = find(devices, (device)=> item.startsWith(device.iotAddress));
        return !existing;
      });
      setOptions(availableDevicesNotAlreadyImported);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const submitForm = () => {
    if (!isEmpty(selectedAddress)) {
      deviceService.importDevice(selectedAddress).then((device: Device) => {
        setCurrentConfiguredDevice(device);
        navigate(`${MEASURERS}/${ADD}`);
      });
    }
  };

  const handleSelect = (value: any) => {
    if (value) {
      setSelectedAddress('' + value);
    } else {
      setSelectedAddress('');
    }
  };

  return (
    <PageContainer
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('device.add')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={12}>
            <p>
              <h4>{t('device.explanation1')}</h4>
            </p>
            <p>
              <h4>{t('device.explanation2')}</h4>
            </p>
          </Grid>

          <Grid item xs={6} sx={{ pr: theme.spacing(2) }}>
            <Autocomplete
              id="my-autocomplete"
              open={open}
              noOptionsText={t('device.noOptionsText')}
              disabled={isLoading}
              onChange={(event, value) => handleSelect(value)}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              onInputChange={(event, newInputValue) =>
                handleSearch(newInputValue)
              }
              options={options}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={'small'}
                  label={t('device.device')}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mb: theme.spacing(2),
          }}
        >
          <Button
            sx={{ textTransform: 'none', ml: theme.spacing(2) }}
            variant="contained"
            type="submit"
            onClick={submitForm}
          >
            {t('import')}
          </Button>
        </Box>
      </Container>
    </PageContainer>
  );
}

export default DeviceDetails;
