import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { enUS, frFR } from '@mui/x-data-grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license-pro';
import { currentConfiguredDevice } from 'atoms/currentConfiguredDevice';
import { measurersConfigurationPending } from 'atoms/measurersConfigurationPending';
import { AuthenticationManager } from 'components/AuthenticationManager/AuthenticationManager';
import { ProtectedRoute } from 'components/common/ProtectedRoute/ProtectedRoute';
import {
  ADD,
  ALARMS,
  ALERTS,
  COMPANIES,
  DEVICES,
  EDIT,
  INDEX,
  MEASUREMENT,
  MEASURERS,
  PARCSTATUS,
  PWA,
  SITES,
  STRUCTURES,
  USERS,
} from 'constants/routes';
import i18n from 'i18next';
import Device from 'models/Device';
import Measurer from 'models/Measurer';
import 'moment/locale/fr';
import { SnackbarProvider } from 'notistack';
import AlarmTriggerDetails from 'pages/Alarms/AlarmTriggerDetails/AlarmTriggerDetails';
import AlarmTriggers from 'pages/Alarms/AlarmTriggers';
import Alerts from 'pages/Alerts/Alerts';
import Companies from 'pages/Companies/Companies';
import Devices from 'pages/Devices/Devices';
import Home from 'pages/Home/Home';
import Measurements from 'pages/Measurements/Measurements';
import MeasurerCreation from 'pages/Measurers/Creation/MeasurerCreation';
import Measurers from 'pages/Measurers/Measurers';
import { Pwa } from 'pages/PWA/Pwa';
import Sites from 'pages/Sites/Sites';
import Structures from 'pages/Structures/Structures';
import { useEffect, useMemo, useState } from 'react';
import { ValidatorProvider } from 'react-class-validator';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import 'theme/styles.css';
import theme from 'theme/theme';
import NotificationManager from '../../components/NotificationManager';
import CompanyDetails from '../Companies/CompanyDetails/CompanyDetails';
import DeviceDetails from '../Devices/Details/DeviceDetails';
import DeviceImport from '../Devices/Import/DeviceImport';
import MeasurerDetails from '../Measurers/Details/MeasurerDetails';
import ParcStatus from '../ParcStatus/ParcStatus';
import SiteDetails from '../Sites/SiteDetails/SiteDetails';
import StructureDetails from '../Structures/StructureDetails/StructureDetails';
import UserDetails from '../Users/UserDetails/UserDetails';
import Users from '../Users/Users';

LicenseInfo.setLicenseKey(
  'cbfd3c824709c87222bf1e01e5666509Tz05OTYzOSxFPTE3NjAwODc1MTgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

function App() {
  const [locale, setLocale] = useState(frFR);
  let location = useLocation();
  const navigate = useNavigate();
  const [isStandalone, setIsStandalone] = useState<boolean>(false);

  const setCurrentConfiguredDeviceState = useSetRecoilState<Device | null>(
    currentConfiguredDevice,
  );
  const setMeasurersConfigurationPendingValue = useSetRecoilState<Measurer[]>(
    measurersConfigurationPending,
  );

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName.startsWith(`${DEVICES}/${ADD}`)) return;
    if (pathName.startsWith(`${DEVICES}/${EDIT}`)) return;
    if (pathName.startsWith(`${MEASURERS}/${ADD}`)) return;
    if (pathName.startsWith(`${MEASURERS}/${EDIT}`)) return;
    setCurrentConfiguredDeviceState(null);
    setMeasurersConfigurationPendingValue([]);
  }, [location]);

  useEffect(() => {
    if (isStandalone) {
      const pathName = location.pathname;
      if (pathName.startsWith(PWA)) return;
      navigate(PWA);
    }
  }, [location, isStandalone]);

  useEffect(() => {
    const mqStandAlone = '(display-mode: standalone)';
    if (window.matchMedia(mqStandAlone).matches) {
      setIsStandalone(true);
    }
  }, []);

  i18n.on('languageChanged', (lng) => {
    if (lng === 'en') {
      setLocale(enUS);
    } else {
      setLocale(frFR);
    }
  });

  // const validatorOptions: ValidatorContextOptions = {
  //   onErrorMessage: (error): string => {
  //    console.log("Error message", error);
  //   },
  //   resultType: 'boolean' // default, can also be set to 'map'
  // }

  const themeWithLocale = useMemo(
    () => createTheme(theme, locale),
    [theme, locale],
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      <SnackbarProvider>
        <NotificationManager />
        <AuthenticationManager>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={'fr'}
          >
            <ValidatorProvider>
              <Routes>
                <Route index element={<Home />} />
                <Route path={SITES}>
                  <Route index element={<Sites />} />
                  <Route
                    path={ADD}
                    element={
                      <ProtectedRoute redirectPath={SITES}>
                        <SiteDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route path={EDIT + '/:id'} element={<SiteDetails />} />
                </Route>
                <Route path={COMPANIES}>
                  <Route index element={<Companies />} />
                  <Route
                    path={ADD}
                    element={
                      <ProtectedRoute redirectPath={COMPANIES}>
                        <CompanyDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route path={EDIT + '/:id'} element={<CompanyDetails />} />
                </Route>
                <Route path={MEASUREMENT} element={<Measurements />} />
                <Route path={ALARMS}>
                  <Route index element={<AlarmTriggers />} />
                  <Route
                    path={ADD}
                    element={
                      <ProtectedRoute redirectPath={ALARMS}>
                        <AlarmTriggerDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={EDIT + '/:id'}
                    element={<AlarmTriggerDetails />}
                  />
                </Route>
                <Route path={DEVICES}>
                  <Route index element={<Devices />} />
                  <Route
                    path={ADD}
                    element={
                      <ProtectedRoute redirectPath={DEVICES}>
                        <DeviceImport />
                      </ProtectedRoute>
                    }
                  />
                  <Route path={EDIT + '/:id'} element={<DeviceDetails />} />
                </Route>
                <Route path={MEASURERS}>
                  <Route index element={<Measurers />} />
                  <Route
                    path={ADD}
                    element={
                      <ProtectedRoute redirectPath={MEASURERS}>
                        <MeasurerCreation />
                      </ProtectedRoute>
                    }
                  />
                  <Route path={EDIT + '/:id'} element={<MeasurerDetails />} />
                </Route>
                <Route path={STRUCTURES}>
                  <Route index element={<Structures />} />
                  <Route
                    path={ADD}
                    element={
                      <ProtectedRoute redirectPath={STRUCTURES}>
                        <StructureDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route path={EDIT + '/:id'} element={<StructureDetails />} />
                </Route>

                <Route path={USERS}>
                  <Route
                    index
                    element={
                      <ProtectedRoute redirectPath={INDEX} onlyAdmin={true}>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={ADD}
                    element={
                      <ProtectedRoute redirectPath={INDEX} onlyAdmin={true}>
                        <UserDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={EDIT + '/:id'}
                    element={
                      <ProtectedRoute redirectPath={INDEX} onlyAdmin={true}>
                        <UserDetails />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path={ALERTS} element={<Alerts />} />
                <Route path={PWA}>
                  <Route index element={<Pwa />} />
                </Route>
                <Route path={PARCSTATUS} element={<ParcStatus />} />
              </Routes>
            </ValidatorProvider>
          </LocalizationProvider>
        </AuthenticationManager>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
