import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { currentConfiguredDevice } from 'atoms/currentConfiguredDevice';
import { measurersConfigurationPending } from 'atoms/measurersConfigurationPending';
import { isAdminOrManagerSelector } from 'atoms/user';
import GoBackButton from 'components/common/GoBackButton/GoBackButton';
import { PageContainer } from 'components/common/PageContainer';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import SpecificParametersForm from 'components/SpecificParametersForm/SpecificParametersForm';
import { MeasurementType } from 'constants/MeasurementType';
import { ADD, ALARMS, DEVICES, EDIT, MEASURERS } from 'constants/routes';
import { get, toLower } from 'lodash';
import Device from 'models/Device';
import Measurer from 'models/Measurer';
import moment from 'moment-timezone';
import { useCallback, useMemo, useState } from 'react';
import { useValidation } from 'react-class-validator';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import MeasurerService from 'services/commons/MeasurerService';
import FileViewerCustom from '../../../components/FileViewer';
import { generateBreadCrumbs } from '../../../utils/generateBreadCrumbs';

export default function MeasurerDetails(): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id: measurerId } = useParams();
  const { t } = useTranslation();
  const isAdminOrManager = useRecoilValue<Boolean>(isAdminOrManagerSelector);
  const currentConfiguredDeviceState = useRecoilValue<Device | null>(
    currentConfiguredDevice,
  );
  const [
    measurersConfigurationPendingValue,
    setMeasurersConfigurationPendingValue,
  ] = useRecoilState<Measurer[]>(measurersConfigurationPending);
  const measurerService = new MeasurerService();
  const [measurerState, setMeasurerState] = useState<Measurer>(
    Measurer.newEmpty,
  );
  const [validateForm, validationErrors] = useValidation(Measurer);

  const getCurrentMeasurer = ({ queryKey }: any) => {
    const measurerId = queryKey[1];
    measurerService.getOne(measurerId).then((measurer: Measurer) => {
      setMeasurerState(measurer);
      return measurer;
    });
  };

  useQuery({
    queryKey: measurerId && ['currentMeasurer', measurerId],
    queryFn: getCurrentMeasurer,
    cacheTime: 5,
    enabled: !!measurerId,
  });

  const handleNavigation = useCallback(() => {
    if (
      !measurersConfigurationPendingValue ||
      measurersConfigurationPendingValue.length === 0
    ) {
      navigate(MEASURERS);
    } else {
      const newMeasurersPending: Measurer[] =
        measurersConfigurationPendingValue.filter((measurer) => {
          return measurer.id !== measurerState.id;
        });
      setMeasurersConfigurationPendingValue(newMeasurersPending);
      if (newMeasurersPending.length === 0) {
        navigate(`${MEASURERS}/${ADD}`);
      } else {
        navigate(`${MEASURERS}/${EDIT}/${newMeasurersPending[0].id}`);
      }
    }
  }, [
    measurerState,
    measurersConfigurationPendingValue,
    setMeasurersConfigurationPendingValue,
  ]);

  const updateLocalStorageLabel = (value: Measurer) => {
    const localStorageMeasurers = localStorage.getItem('measurers');
    if (!localStorageMeasurers) return;
    try {
      const measurersArray = JSON.parse(localStorageMeasurers);
      if (!measurersArray) return;
      const newMeasurerArray = measurersArray.map((item: Measurer) => {
        if (item.id === value.id) {
          return value;
        }
        return item;
      });
      localStorage.setItem('measurers', JSON.stringify(newMeasurerArray));
    } catch (error) {
      console.log('ERROR ', error);
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    if (await validateForm(measurerState)) {
      localStorage.removeItem('measurers');
      measurerService.update(measurerState).then(() => {
        updateLocalStorageLabel(measurerState);
        if (
          ( !measurersConfigurationPendingValue ||
            measurersConfigurationPendingValue.length === 0) && (measurerState.measurementType === MeasurementType.WATER_DEPTH_RELATIVE || measurerState.measurementType === MeasurementType.WATER_HEIGHT_RELATIVE)
        ) {
          const calibrationDate: any = get(
            measurerState,
            'specificParameters.CALIBRATION_DATE.fieldValue',
          );
          if (calibrationDate) {
            measurerService
              .calibrate({
                measurerId: measurerState.id,
                dateString: moment
                  .tz(calibrationDate, moment.tz.guess())
                  .utc()
                  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              })
              .then(() => {
                handleNavigation();
              });
          } else {
            handleNavigation();
          }
        } else {
          handleNavigation();
        }
      });
    }
  };

  const goToLinkedAlarms = () => {
    navigate(ALARMS, {
      state: {
        measurer: `${measurerState.device.deviceIdentifier} ${measurerState.measurementType}`,
      },
    });
  };

  const breadCrumbs = generateBreadCrumbs(measurerState.device.location);

  const handleChangeLabel = (e: any) => {
    const { value } = e.target;

    setMeasurerState((prevState: any) => ({
      ...prevState,
      measurerLabel: value,
    }));
  };

  const saveButtonLabel: string = useMemo(() => {
    if (!t) return '';
    if (!measurersConfigurationPendingValue) return t('navigation.validate');
    if (measurersConfigurationPendingValue.length === 0)
      return t('navigation.validate');
    if (measurersConfigurationPendingValue.length < 2)
      return t('measurer.saveAndAddNewMeasurer');
    return t('measurer.saveAndConfigureNext');
  }, [measurersConfigurationPendingValue, t]);

  const showEndConfigurationButton = useMemo(() => {
    if (
      measurersConfigurationPendingValue &&
      measurersConfigurationPendingValue.length &&
      measurersConfigurationPendingValue.length === 1
    ) {
      return currentConfiguredDeviceState !== null;
    }
  }, [measurersConfigurationPendingValue, currentConfiguredDeviceState]);

  const finishDeviceConfiguration = useCallback(() => {
    if (!currentConfiguredDeviceState?.id) navigate(MEASURERS);
    else {
      navigate(`${DEVICES}/${EDIT}/${currentConfiguredDeviceState?.id}`);
    }
  }, [currentConfiguredDeviceState, navigate]);

  return (
    <PageContainer
      breadCrumbs={breadCrumbs}
      headerContent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            pr: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('navigationMenu.measurersDetails')}
          </Typography>
        </Box>
      }
    >
      <GoBackButton />
      <Container
        sx={{
          mt: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ mb: theme.spacing(2) }}>
          <Grid item xs={6} sx={{ display: 'flex', pr: theme.spacing(2) }}>
            <Typography color={'primary'} style={{ fontWeight: 'bold' }}>
              {t('measurer.device')}
            </Typography>
            :<Typography>&nbsp;{measurerState.device.deviceName}</Typography>
          </Grid>

          <Grid item xs={6} sx={{ display: 'flex', pr: theme.spacing(2) }}>
            <Typography color={'primary'} style={{ fontWeight: 'bold' }}>
              {t('measurer.measurerCategory')}
            </Typography>
            :
            <Typography>
              &nbsp;
              {t(`measurerCategory.${toLower(measurerState.measurerCategory)}`)}
            </Typography>
          </Grid>

          <Grid item xs={6} sx={{ display: 'flex', pr: theme.spacing(2) }}>
            <Typography color={'primary'} style={{ fontWeight: 'bold' }}>
              {t('measurer.measurementType')}
            </Typography>
            :
            {measurerState.measurementType ? (
              <Typography>
                &nbsp;
                {t(
                  `measurementType.${measurerState.measurementType.toLowerCase()}`,
                )}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={6} sx={{ pt: theme.spacing(2) }}>
            <TextFieldCustom
              label={'Label'}
              onChange={handleChangeLabel}
              name={'measurerLabel'}
              value={measurerState?.measurerLabel || ''}
            />
          </Grid>
        </Grid>
        <>
          <SpecificParametersForm
            measurerState={measurerState}
            setMeasurerState={setMeasurerState}
            validationErrors={validationErrors}
            validateForm={validateForm}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              mb: theme.spacing(2),
            }}
          >
            {measurerState.id && (
              <Button
                sx={{ textTransform: 'none' }}
                variant="contained"
                type="submit"
                onClick={goToLinkedAlarms}
              >
                {t('measurer.goToChildren')}
              </Button>
            )}
            {isAdminOrManager && (
              <Button
                sx={{ textTransform: 'none', ml: theme.spacing(2) }}
                variant="contained"
                type="submit"
                onClick={submitForm}
              >
                {saveButtonLabel}
              </Button>
            )}
            {isAdminOrManager && showEndConfigurationButton && (
              <Button
                sx={{ textTransform: 'none', ml: theme.spacing(2) }}
                variant="contained"
                type="submit"
                onClick={() => finishDeviceConfiguration()}
              >
                {t('measurer.saveAndFinishConfiguration')}
              </Button>
            )}
          </Box>
        </>
        <FileViewerCustom canEdit measurers={[measurerState]} />
      </Container>
    </PageContainer>
  );
}
